import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import currentDateIndicator from 'highcharts/modules/current-date-indicator';
import images from '../../../assets/images';
import ArchiveGoalDialog from '../../../components/dialog/business-goals-dialogue/ArchiveGoalDialog';
import CreateBusinessGoalDialogue from '../../../components/dialog/business-goals-dialogue/CreateBusinessGoalDialog';
import CreateKpiGoalDialog from '../../../components/dialog/business-goals-dialogue/CreateKpiGoalDialog';
import useWindowSize from '../../../shared/hooks/useWindowResize';
import instance from '../../../shared/interceptor';
import { getGoalDetailCom } from '../../../shared/services/common.service';
import GoalDetailCard from '../../../shared/shared-comp/GoalDetailCard';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import {
  goalGraphState,
  setGoalsGraphReset,
} from '../../../shared/store/slices/goalSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import customPlugin from '../../../shared/util/customPlugin';
import {
  formatDate,
  getColorCodeAndClassForGoals,
  getFullname,
  globalError,
  setLoader,
  sortListAcc,
  transNavigation,
} from '../../../shared/util/util';
import {
  goalsStatusData,
  goalsTimeLine,
} from '../../dashboard/dashboardGraphData';
import '../company.scss';
import './business-goals.scss';

currentDateIndicator(Highcharts);
customPlugin(Highcharts);
HighchartsMore(Highcharts);

export default function BusinessGoalDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const navigate = useNavigate();
  const resetGraph = useSelector(goalGraphState);
  const location = useLocation();
  const [width, height] = useWindowSize();

  const [openDialogueKPI, setOpenDialogueKPI] = useState(false);
  const [businessDetailsTabValue, setBusinessDetailsTabValue] =
    useState('Overview');
  const [goalDetail, setGoalDetail] = useState(null);
  const [listKpi, setListKpi] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [timelineData, setTimelineData] = useState(null);
  const [spaceData, setSpaceData] = useState(null);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [archive, setArchive] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = useState({});
  const [sortAsc, setSortAsc] = useState(false);
  const [random, setRandom] = useState(1);
  const [timelineLoading, setTimelineLoading] = useState(true);
  const [kpiLoading, setKpiLoading] = useState(true);
  const [spaceDataLoading, setSpaceDataLoading] = useState(true);

  useEffect(() => {
    getGoalDetail();
    getKpis();
    getStatusData();
    getPerformanceData();
    getSpaceList();
  }, []);

  useEffect(() => {
    setRandom((prev) => prev + 1);
  }, [width, height]);

  useEffect(() => {
    if (resetGraph && location?.pathname?.includes('business-goals/')) {
      setRandom((prev) => prev + 1);
    }
    return () => {
      dispatch(setGoalsGraphReset(false));
    };
  }, [resetGraph]);

  const getGoalDetail = async () => {
    try {
      let res = await getGoalDetailCom(params, compSel, dispatch);
      setGoalDetail(res);
    } catch (e) {
      globalError(dispatch, e);
    }
  };

  const getKpis = async () => {
    try {
      setKpiLoading(true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis`;
      let res = await instance.get(url);
      setListKpi(res?.['kpis']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setKpiLoading(false);
    }
  };

  const getSpaceList = async () => {
    try {
      setSpaceDataLoading(true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/spaces`;
      let res = await instance.get(url);
      setSpaceData(res);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setSpaceDataLoading(false);
    }
  };

  const getStatusData = async () => {
    try {
      // setLoader(dispatch, true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis/status_data`;
      let res = await instance.get(url);
      setStatusData(res);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const getPerformanceData = async () => {
    try {
      setTimelineLoading(true);
      const goalId = params?.goal_id;
      const url = `${COMPANY}/${compSel?.id}/company_goals/${goalId}/kpis/performance_data`;
      let res = await instance.get(url);
      setTimelineData(
        res?.['kpis'].flat()?.map((kpi, idx) => ({
          name: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)
            ?.class,
          color: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)
            ?.color,
          low: new Date(kpi?.created_at).getTime(),
          high: new Date(kpi?.due_date).getTime(),
          kpi_name: kpi?.name,
        }))
      );
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setTimelineLoading(false);
    }
  };

  const ideaboardTabHandleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setBusinessDetailsTabValue(newValue);
  };

  const handleClose = (e, render = null) => {
    setOpenDialogueKPI(false);
    setOpenCreateDialogue(false);
    setArchive(null);
    if (render) {
      getGoalDetail();
      getKpis();
      getStatusData();
      getPerformanceData();
      getSpaceList();
    }
  };

  const handleDotClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setExpansion = (idx) => {
    if (idx in expanded) {
      setExpanded((prev) => ({ ...prev, [idx]: !prev?.[idx] }));
    } else {
      setExpanded((prev) => ({ ...prev, [idx]: true }));
    }
  };

  const redirect = (space, card) => {
    if (
      (card?.state == 'ongoing' ||
        card?.state == 'running' ||
        card?.state == 'completed' ||
        card?.state == 'ready_for_kickoff') &&
      space?.can_update
    ) {
      window.open(card?.home_url, '_self');
    } else if (
      (!card?.state ||
        (card?.state != 'ongoing' && card?.state != 'completed')) &&
      (card?.state == 'draft' || card?.state == 'pending_approval')
    ) {
      React.startTransition(() => {
        navigate(
          `/company/spaces/${space?.id}/playground/${card.enterprise_id}`
        );
      });
    }
  };

  const sortSpaceList = () => {
    setSortAsc(!sortAsc);
    setSpaceData({
      ...spaceData,
      spaces: sortListAcc(spaceData?.spaces, 'name', sortAsc),
    });
  };

  //================================
  return (
    <>
      <div className="goals-detail-page" aria-label="business-goal-detail-page">
        <div className="page-container top-space">
          <div className="main-page-header">
            <div className="page-breacrumb">
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li>
                    <a
                      onClick={() =>
                        transNavigation(
                          React.startTransition,
                          navigate,
                          '/company/company-settings'
                        )
                      }
                    >
                      Company
                    </a>
                  </li>
                  <li>
                    <Link to="/company/business-goals">Business goals</Link>
                  </li>
                  <li>{goalDetail?.name}</li>
                </ul>
              </div>
              <RightPageHeader />
            </div>
            <div className="page-title">
              <div className="title-wrap">
                <h2>{goalDetail?.name}</h2>
                <p className="count">{goalDetail?.description}</p>
              </div>
              <div className="right-filter">
                <div className="button-wrap">
                  {/* <Button variant="contained" className="sm-btn purple-btn">Edit</Button> */}
                  <Button
                    variant="contained"
                    className="sm-btn is-icon-btn square-btn-three-dots"
                    onClick={(e) => handleDotClick(e)}
                  >
                    <em
                      aria-label="three-dot-btn"
                      className="icon be-vertical-dots"
                    ></em>
                  </Button>
                  <Menu
                    id="basic-goal-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className={'three-dot-menu-paper'}
                  >
                    <MenuItem
                      data-testid="edit-dialog"
                      className="toggle-menu-item-row"
                      onClick={(e) => {
                        setOpenCreateDialogue(true);
                        setAnchorEl(null);
                      }}
                    >
                      <em className="icon be-edit"></em> Edit
                    </MenuItem>
                    <MenuItem
                      data-testid="archive-dialog"
                      className="toggle-menu-item-row"
                      aria-label="archive-btn"
                      onClick={(e) => {
                        setArchive(goalDetail);
                        setAnchorEl(null);
                      }}
                    >
                      <em className="icon be-archive"></em>{' '}
                      {goalDetail?.archive ? 'Unarchive' : 'Archive'}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="page-filters-wrapper align-items-start"></div> */}
          <div className="inner-section-wrap">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <div className="tab-label-wrapper">
                  <TabContext value={businessDetailsTabValue}>
                    <Box>
                      <TabList
                        onChange={ideaboardTabHandleChange}
                        aria-label=""
                      >
                        <Tab label="Overview" value="Overview" />
                        <Tab label="Resources" value="Resources" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </div>
            </div>
            <Box className="tab-context-wrapper">
              <TabContext value={businessDetailsTabValue}>
                <TabPanel value="Overview">
                  <GoalDetailCard
                    {...{
                      goalDetail,
                      getGoalDetail,
                      setOpenEditDialogue: setOpenCreateDialogue,
                    }}
                  />

                  {/* <div className="goal-section">
                    <h4>What's being build for this goal</h4>
                    <div className="no-data-wrapper no-ideas-yet" aria-label="empty-state">
                      <em className="thumb"><img src={images.emptyStateIllustration} alt="Spaces Flag" /></em>
                      <h4 aria-label="no-space">No assigned spaces yet</h4>
                      <p>Assign a space, so your members can work towards this goal.</p>
                      <Button variant="contained" className="sm-btn light-purple-btn">Assign space</Button>
                    </div>
                  </div> */}
                  <div className="goal-section">
                    <div className="title-wrap">
                      <h4>KPIs</h4>
                      <Button
                        variant="contained"
                        className="xs-btn grey-btn light-purple-btn"
                        onClick={() => setOpenDialogueKPI(true)}
                      >
                        Add KPI
                      </Button>
                    </div>

                    {/* No data found - empty state */}

                    {!timelineData?.length &&
                      !listKpi?.accomplished?.length &&
                      !timelineLoading && (
                        <div
                          className="no-data-wrapper no-ideas-yet"
                          aria-label="empty-state"
                        >
                          <em className="thumb">
                            <img
                              src={images.emptyStateIllustration}
                              alt="Spaces Flag"
                            />
                          </em>
                          <h4 aria-label="no-space">No KPIs yet</h4>
                          <p>Add a KPI to measure your business goal.</p>
                          <Button
                            variant="contained"
                            className="sm-btn light-purple-btn"
                            onClick={() => setOpenDialogueKPI(true)}
                          >
                            Add KPI
                          </Button>
                        </div>
                      )}

                    {statusData?.total_count > 0 && (
                      <div className="kpi-count">
                        <span>{statusData?.total_count ?? 0}</span> total (
                        <span>{statusData?.accomplished ?? 0}</span>{' '}
                        accomplished, <span>{statusData?.on_track ?? 0}</span>{' '}
                        on track, <span>{statusData?.progressing ?? 0}</span>{' '}
                        progressing, <span>{statusData?.off_track ?? 0}</span>{' '}
                        off track, <span>{statusData?.not_started ?? 0}</span>{' '}
                        not started)
                      </div>
                    )}

                    {timelineLoading && (
                      <LoaderComp
                        border={true}
                        style={{ height: '30vh', marginTop: '2.4rem' }}
                      />
                    )}
                    {!timelineLoading && timelineData?.length > 0 && (
                      <div className="graph-wrapper">
                        <div className="kpi-performance">
                          <div style={{ width: '63%' }} key={random}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={goalsTimeLine(timelineData, '280px')}
                            />
                          </div>
                          <div
                            style={{ width: '37%' }}
                            className="pie-chart-wrapper"
                            key={statusData?.total_count}
                          >
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={goalsStatusData(
                                statusData,
                                '280px',
                                true,
                                true
                              )}
                            />
                          </div>
                          {/* <img src={(images as any).KPIPerformanceGraph} alt="KPI Performance graph"/> */}
                        </div>
                      </div>
                    )}
                    <div className="efficiancy-cards-wrapper">
                      {kpiLoading && <LoaderComp maxHeight="70rem" />}
                      <div className="card-section">
                        {listKpi?.not_accomplished?.map((acc) => (
                          <div
                            key={acc?.id}
                            className={`card ${getColorCodeAndClassForGoals(acc?.status, false)?.class}`}
                            onClick={() =>
                              React.startTransition(() => {
                                navigate(
                                  `/company/business-goals/${params?.goal_id}/kpi/${acc?.id}`,
                                  { state: { goalName: goalDetail?.name } }
                                );
                              })
                            }
                          >
                            <h4>{acc?.name}</h4>
                            <div className="count">
                              <span className="spaces">
                                <strong>{acc?.spaces?.length ?? 0}</strong>
                                &nbsp;&nbsp;Spaces
                                <ul className="custom-tooltip">
                                  {acc?.spaces?.map((sp) => (
                                    <li key={sp?.id}>
                                      <em
                                        className="icon be-multiple-users"
                                        style={{ backgroundColor: sp?.color }}
                                      ></em>
                                      <span>{sp?.name}</span>
                                    </li>
                                  ))}
                                </ul>
                              </span>
                              <span className="separator"></span>
                              <span>
                                <strong>{acc?.build_card_count ?? 0}</strong>
                                &nbsp;&nbsp;Buildcards
                              </span>
                            </div>
                            <div className="text-description">
                              {acc?.description}
                            </div>
                            <div className="card-bottom">
                              <span className="target-date">
                                Target {formatDate(acc?.due_date, false, true)}
                              </span>
                              <span className="status">
                                {acc?.status?.replace('_', ' ')}{' '}
                                <em
                                  className="dot"
                                  style={{
                                    backgroundColor:
                                      getColorCodeAndClassForGoals(
                                        acc?.status,
                                        false
                                      )?.color,
                                  }}
                                ></em>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {listKpi?.accomplished?.length > 0 && (
                        <div className="accomplished-cards">
                          <h3>Accomplished KPIs</h3>
                          <div className="card-section">
                            {!listKpi?.accomplished?.length && (
                              <p className="epmty-card-text">
                                No accomplished cards yet!
                              </p>
                            )}
                            {listKpi?.accomplished?.map((acc) => (
                              <div className="card" key={acc?.id}>
                                <h4
                                  onClick={() =>
                                    React.startTransition(() => {
                                      navigate(
                                        `/company/business-goals/${params?.goal_id}/kpi/${acc?.id}`,
                                        {
                                          state: { goalName: goalDetail?.name },
                                        }
                                      );
                                    })
                                  }
                                >
                                  {acc?.name}
                                </h4>
                                <div className="count">
                                  <span className="spaces">
                                    <strong>{acc?.spaces?.length ?? 0}</strong>
                                    &nbsp;&nbsp;Spaces
                                    <ul className="custom-tooltip">
                                      {acc?.spaces?.map((sp) => (
                                        <li key={sp?.id}>
                                          <em
                                            className="icon be-multiple-users"
                                            style={{
                                              backgroundColor: sp?.color,
                                            }}
                                          ></em>
                                          <span>{sp?.name}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  </span>
                                  <span className="separator"></span>
                                  <span>
                                    <strong>
                                      {acc?.build_card_count ?? 0}
                                    </strong>
                                    &nbsp;&nbsp;Buildcards
                                  </span>
                                </div>
                                <div className="text-description">
                                  {acc?.description}
                                </div>
                                <div className="card-bottom">
                                  <span className="target-date">
                                    Target{' '}
                                    {formatDate(acc?.due_date, false, true)}
                                  </span>
                                  <span className="status">
                                    Accomplished{' '}
                                    <img
                                      src={images.checkSuccessRounded}
                                      alt="icon"
                                    />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="separator"></div> */}

                    {/* Spaces working toward goal */}
                    <div className="spaces-in-goal-wrapper">
                      <div className="title-wrap">
                        <h3>Spaces working toward this goal</h3>
                        <span className="count">
                          {/* <span className="spaces"><strong>6</strong>&nbsp;&nbsp;Spaces</span> */}
                          {!spaceDataLoading && (
                            <span className="spaces">
                              <strong>{spaceData?.spaces_count}</strong>
                              &nbsp;&nbsp;Spaces
                              <ul className="custom-tooltip">
                                {spaceData?.spaces?.map((space) => (
                                  <li key={space?.id}>
                                    <em
                                      className="icon be-multiple-users"
                                      style={{
                                        backgroundColor:
                                          space?.color ?? '#860DFF',
                                      }}
                                    ></em>
                                    <span>{space?.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </span>
                          )}
                          <span className="separator"></span>
                          {!spaceDataLoading && (
                            <span>
                              <strong>
                                {spaceData?.spaces?.reduce(
                                  (ini, acc) => acc?.build_cards_count + ini,
                                  0
                                )}
                              </strong>
                              &nbsp;&nbsp;Buildcards
                            </span>
                          )}
                        </span>
                      </div>
                      {spaceDataLoading && (
                        <LoaderComp border={true} style={{ height: '20vh' }} />
                      )}
                      {!spaceDataLoading && spaceData?.spaces?.length > 0 && (
                        <div className="table-wrapper table-responsive accordion-head-table-wrap">
                          <TableContainer>
                            <Table className="outer-table business-goals-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    NAME{' '}
                                    <em
                                      className={`icon ${!sortAsc ? 'be-down-arrow-1' : 'be-down-arrow-1 move-up'}`}
                                      aria-label="sort-btn"
                                      onClick={sortSpaceList}
                                    ></em>
                                  </TableCell>
                                  <TableCell className="text-left">
                                    KPIS
                                  </TableCell>
                                  <TableCell className="text-left">
                                    BUILDCARDS
                                  </TableCell>
                                  <TableCell className="text-left">
                                    USED CREDITS
                                  </TableCell>
                                  <TableCell>OWNER</TableCell>
                                  <TableCell className="text-left"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {spaceData?.spaces?.map((space, idx) => (
                                  <React.Fragment key={space?.id}>
                                    <TableRow>
                                      <TableCell>
                                        {space?.name?.length > 22 ? (
                                          <Tooltip
                                            placement="top"
                                            title={space?.name}
                                            style={{ width: 'auto' }}
                                          >
                                            <>
                                              <em
                                                className={`icon be-chevron-down ${expanded?.[idx] ? 'toggle-down' : ''} ${!space?.build_cards?.length ? 'hidden' : ''}`}
                                                onClick={() =>
                                                  space?.build_cards?.length
                                                    ? setExpansion(idx)
                                                    : null
                                                }
                                              ></em>{' '}
                                              <strong className="truncate-name">
                                                {space?.name}
                                              </strong>
                                            </>
                                          </Tooltip>
                                        ) : (
                                          <>
                                            <em
                                              className={`icon be-chevron-down ${expanded?.[idx] ? 'toggle-down' : ''} ${!space?.build_cards?.length ? 'hidden' : ''}`}
                                              onClick={() =>
                                                space?.build_cards?.length
                                                  ? setExpansion(idx)
                                                  : null
                                              }
                                            ></em>{' '}
                                            <strong>{space?.name}</strong>
                                          </>
                                        )}
                                      </TableCell>
                                      <TableCell className="text-left">
                                        {space?.kpis_count}
                                      </TableCell>
                                      <TableCell className="text-left">
                                        {space?.build_cards_count}
                                      </TableCell>
                                      <TableCell className="text-left">
                                        {space?.consumed_credits}
                                      </TableCell>
                                      <TableCell>
                                        {getFullname(
                                          space?.owner?.first_name,
                                          space?.owner?.last_name
                                        )?.length > 22 ? (
                                          <Tooltip
                                            placement="top"
                                            title={getFullname(
                                              space?.owner?.first_name,
                                              space?.owner?.last_name
                                            )}
                                            style={{ width: 'auto' }}
                                          >
                                            <span className="truncate-name">
                                              {getFullname(
                                                space?.owner?.first_name,
                                                space?.owner?.last_name
                                              )}
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <span>
                                            {getFullname(
                                              space?.owner?.first_name,
                                              space?.owner?.last_name
                                            ) ?? '-'}
                                          </span>
                                        )}
                                      </TableCell>
                                      <TableCell className="text-left">
                                        <a
                                          className="underline"
                                          onClick={() =>
                                            React.startTransition(() => {
                                              navigate(
                                                `/company/spaces/${space?.id}/overview`
                                              );
                                            })
                                          }
                                        >
                                          Details
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                    {expanded[idx] ? (
                                      <TableRow>
                                        <TableCell
                                          style={{ paddingLeft: '0' }}
                                          colSpan={6}
                                          className="collapse-table-wrap"
                                        >
                                          <Table className="inner-table">
                                            <TableBody>
                                              {space?.build_cards?.map(
                                                (build_card) => (
                                                  <TableRow
                                                    key={build_card?.id}
                                                  >
                                                    <TableCell>
                                                      {(
                                                        build_card?.alias_name ??
                                                        build_card?.name
                                                      )?.length > 22 ? (
                                                        <Tooltip
                                                          placement="top"
                                                          title={
                                                            build_card?.alias_name ??
                                                            build_card?.name
                                                          }
                                                          style={{
                                                            width: 'auto',
                                                          }}
                                                        >
                                                          <strong>
                                                            <a
                                                              className="truncate-name"
                                                              onClick={() =>
                                                                redirect(
                                                                  space,
                                                                  build_card
                                                                )
                                                              }
                                                            >
                                                              {build_card?.alias_name ??
                                                                build_card?.name}
                                                            </a>
                                                          </strong>
                                                        </Tooltip>
                                                      ) : (
                                                        <strong>
                                                          <a
                                                            onClick={() =>
                                                              redirect(
                                                                space,
                                                                build_card
                                                              )
                                                            }
                                                          >
                                                            {build_card?.alias_name ??
                                                              build_card?.name}
                                                          </a>
                                                        </strong>
                                                      )}
                                                    </TableCell>
                                                    <TableCell className="text-left"></TableCell>
                                                    <TableCell className="text-left"></TableCell>
                                                    <TableCell className="left-spacing text-left warning-col">
                                                      {build_card?.final_cost}
                                                      {(build_card?.state ==
                                                        'draft' ||
                                                        build_card?.state ==
                                                          'pending_approval') &&
                                                        !build_card?.consumed_credits && (
                                                          <Tooltip
                                                            className="Manoj"
                                                            placement="right"
                                                            title={`Showing estimated credits as Builcard is in ${build_card?.state}. These credits are not included in total used credit of the respective space.`}
                                                            style={{
                                                              width: 'auto',
                                                            }}
                                                          >
                                                            <img
                                                              src={
                                                                images?.warningIcon
                                                              }
                                                              alt="warning"
                                                            />
                                                          </Tooltip>
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="left-spacing">
                                                      {getFullname(
                                                        build_card?.owner
                                                          ?.first_name,
                                                        build_card?.owner
                                                          ?.last_name
                                                      )?.length > 15 ? (
                                                        <Tooltip
                                                          placement="top"
                                                          title={getFullname(
                                                            build_card?.owner
                                                              ?.first_name,
                                                            build_card?.owner
                                                              ?.last_name
                                                          )}
                                                          style={{
                                                            width: 'auto',
                                                          }}
                                                        >
                                                          <span className="truncate-name">
                                                            {getFullname(
                                                              build_card?.owner
                                                                ?.first_name,
                                                              build_card?.owner
                                                                ?.last_name
                                                            )}
                                                          </span>
                                                        </Tooltip>
                                                      ) : (
                                                        <span>
                                                          {getFullname(
                                                            build_card?.owner
                                                              ?.first_name,
                                                            build_card?.owner
                                                              ?.last_name
                                                          ) ?? '-'}
                                                        </span>
                                                      )}
                                                    </TableCell>
                                                    <TableCell className="text-left"></TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableCell>
                                      </TableRow>
                                    ) : null}
                                  </React.Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      )}
                    </div>
                    {/* Spaces working toward goal end */}
                  </div>
                </TabPanel>
                {/* Tab content for Resources */}
                <TabPanel value="Resources">
                  <div className="goal-section resources-tab-section">
                    <h4>Resources</h4>
                    {/* No data found - empty state */}
                    <div
                      className="no-data-wrapper no-ideas-yet"
                      aria-label="empty-state"
                    >
                      <em className="thumb">
                        <img
                          src={images.emptyStateIllustration}
                          alt="Spaces Flag"
                        />
                      </em>
                      <h4 aria-label="no-space">No resources yet</h4>
                      <p>
                        Assign a space, so your members can work towards this
                        goal.
                      </p>
                      <Button variant="contained" className="sm-btn grey-btn">
                        Resources
                      </Button>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>

      {/* Create KPI for goal dialogue */}
      {openDialogueKPI && (
        <CreateKpiGoalDialog {...{ openDialogueKPI, handleClose }} />
      )}
      {/* Create KPI for goal dialogue close */}

      {/* Edit business goal dialogue start */}
      {openCreateDialogue && (
        <CreateBusinessGoalDialogue
          {...{ openCreateDialogue, handleClose, goalDetail }}
        />
      )}
      {/* Edit business goal dialogue end */}

      {archive && <ArchiveGoalDialog {...{ archive, handleClose }} />}
    </>
  );
}
